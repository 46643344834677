import React, { useState, useEffect } from 'react';
import './LoginPage.css';
import { login, forgotPassword, confirmForgotPassword, register } from '../../cognitoAuth';
import { useNavigate } from 'react-router-dom';
import ResetPassword from '../forgotPassword/ResetPassword';
import vertekalBg from '../../assets/vertekal_background.png';
import vertekalLogin from '../../assets/vertekal_login_page.png';
import { Box, Button, Flex, FormControl, FormLabel, Heading, Image, Input, Text, Spacer } from '@chakra-ui/react';

const ChangePassword = ({ email, handleChangePassword, onCancel }) => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    handleChangePassword(email, oldPassword, newPassword);
  };

  return (
    <Box>
      <Heading as="h2">Change Password</Heading>
      <form onSubmit={handleSubmit}>
        <FormControl id="oldPassword" isRequired>
          <FormLabel>Old Password</FormLabel>
          <Input
            type="password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
          />
        </FormControl>
        <FormControl id="newPassword" isRequired>
          <FormLabel>New Password</FormLabel>
          <Input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </FormControl>
        <Button type="submit">Change Password</Button>
        <Button onClick={onCancel}>Cancel</Button>
      </form>
    </Box>
  );
};

const LoginPage = ({ onLogin, handleChangePassword }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { result, userGroups } = await login(email, password);
      onLogin(email, password, userGroups);
      navigate('/splash');
    } catch (error) {
      console.error('Login error:', error);
      window.alert('Invalid username or password.');
      if (error.message === 'FORCE_CHANGE_PASSWORD') {
        setShowChangePassword(true);
      }
    }
  };

  const handleForgotPassword = async () => {
    try {
      await forgotPassword(email);
      setShowResetPassword(true);
    } catch (error) {
      console.error('Forgot password error:', error);
      alert('Error sending password reset email. Please enter a valid email address.');
    }
  };

  const onResetPassword = async (email, verificationCode, newPassword) => {
    try {
      await confirmForgotPassword(email, verificationCode, newPassword);
      setShowResetPassword(false);
      navigate('/login');
    } catch (error) {
      console.error('Reset password error:', error);
      alert('Error resetting password.');
    }
  };

  useEffect(() => {
    const isEmailValid = /\S+@\S+\.\S+/.test(email);
    setIsButtonDisabled(!(isEmailValid && password.length > 0));
  }, [email, password]);

  if (showResetPassword) {
    return (
      <ResetPassword
        email={email}
        onResetPassword={onResetPassword}
        onCancel={() => setShowResetPassword(false)}
      />
    );
  }

  if (showChangePassword) {
    return (
      <ChangePassword
        email={email}
        handleChangePassword={handleChangePassword}
        onCancel={() => setShowChangePassword(false)}
      />
    );
  }

  return (
    <Flex className="login-page" direction="column" align="center" justify="center" maxH="100vh" backgroundImage={vertekalBg} backgroundSize="cover" backgroundPosition="center" backgroundRepeat="no-repeat">
      <Box className="login-form-container">
      <Image justify="center" src={vertekalLogin} alt="Vertekal Logo" className='login-logo'mb={8}/>
        {/*<Text className="login-header-container" mb={4}>
          Please enter your username and password to access your account. If you're new
          here, feel free to register by clicking on the "Register" link below.
        </Text>*/}
        <form onSubmit={handleSubmit} className="login-form">
          <FormControl id="email" mb={4}>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              focusBorderColor='black'
              bg='white'
            />
          </FormControl>
          <FormControl id="password" mb={4}>
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              focusBorderColor='black'
              bg='white'
            />
          </FormControl>
          <Button
            type="submit"
            w="100%"
            mt={4}
            backgroundColor={isButtonDisabled ? 'gray' : 'black'}
            color="white"
            _hover={{ backgroundColor: isButtonDisabled ? 'gray' : 'gray.700' }}
            isDisabled={isButtonDisabled}
          >
            Login
          </Button>
        </form>
        <Flex mt={4} w="100%">
        <Box>
            <Button onClick={() => navigate('/Register')} variant="link">
              Don't have an account?
            </Button>
          </Box>
          <Spacer />
          <Box>
            <Button onClick={handleForgotPassword} variant="link">
              Forgot Password?
            </Button>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};

export default LoginPage;