import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  SimpleGrid,
  Heading,
} from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import axios from 'axios';
import CreateCandidate from './CreateCandidate';
import ResumeReviewer from './ResumeReviewer';
import EditCandidate from './EditCandidate';
import CandidateList from './CandidateList';
import ResumeChat from './ResumeChat';

const ActionCard = ({ title, description, onOpen }) => (
  <Box
    as="button"
    border="1px"
    borderColor="gray.200"
    borderRadius="md"
    p={5}
    boxShadow="md"
    w="100%"
    textAlign="left"
    onClick={onOpen}
    _hover={{
      bg: "gray.100",
      cursor: "pointer"
    }}
  >
    <Heading size="md" mb={4}>{title}</Heading>
    <p>{description}</p>
  </Box>
);


const Recruit = () => {
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [isViewModalOpen, setViewModalOpen] = useState(false);
  const [isResumeModalOpen, setResumeModalOpen] = useState(false);
  const [isResumeChatModalOpen, setResumeChatModalOpen] = useState(false);
  const [candidates, setCandidates] = useState([]);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [reloadCandidates, setReloadCandidates] = useState(false);

  const loadUsers = async () => {
    try {
      const response = await axios.post(process.env.REACT_APP_CANDIDATE_API_URL, {
        action: 'getAll'
      });

      const responseData = JSON.parse(response.data.body);
      setCandidates(responseData.candidates);
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditCandidate = (candidate) => {
    setSelectedCandidate(candidate);
    setEditModalOpen(true);
  };

  useEffect(() => {
    loadUsers();
    if (reloadCandidates) {
      setReloadCandidates(false);
    }
  }, [reloadCandidates]);


  return (
    <Box p={12}>
      <SimpleGrid columns={3} spacing={10}>
        <ActionCard
          title="Add Candidate"
          description="Add a new candidate to the database."
          onOpen={() => setAddModalOpen(true)}
        />
        <ActionCard
          title="Resume Reviewer"
          description="Add and review resumes."
          onOpen={() => setResumeModalOpen(true)}
        />
        <ActionCard
          title="Resume Chat"
          description="Chat with the ResumeGPT agent." 
          onOpen={() => setResumeChatModalOpen(true)}
        />
      </SimpleGrid>

      {/* Add Candidate Modal */}
      <CreateCandidate
        isAddModalOpen={isAddModalOpen}
        setAddModalOpen={setAddModalOpen}
        onCandidateCreated={() => setReloadCandidates(true)}
      />

      {/* Job Requirements Modal */}
      <ResumeReviewer
        isResumeModalOpen={isResumeModalOpen}
        setResumeModalOpen={setResumeModalOpen}
      />

      {/* Resume Chat Modal */}
      <ResumeChat
        isResumeChatModalOpen={isResumeChatModalOpen}
        setResumeChatModalOpen={setResumeChatModalOpen}
      />

      <Box mt={10}>
        <Heading size="lg" mb={4}>Recruiting Pipeline</Heading>
        <CandidateList
          candidates={candidates}
          onEdit={handleEditCandidate}
        />

        {isEditModalOpen && selectedCandidate && (
          <EditCandidate
            candidate={selectedCandidate}
            isEditModalOpen={isEditModalOpen}
            setEditModalOpen={setEditModalOpen}
            onCandidateUpdated={() => setReloadCandidates(true)}
          />
        )}
      </Box>

    </Box>
  );
};

export default Recruit;
