import { CognitoUserPool, CognitoUser, AuthenticationDetails, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { Amplify } from 'aws-amplify';
import { Auth } from 'aws-amplify';
import AWS from 'aws-sdk';
import axios from 'axios';

// Configure Amplify with Cognito details
Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    region: 'us-east-1',
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  }
});

// Helper function to make API requests
const makeApiRequest = async (action, data = {}) => {
  try {
    const response = await axios.post(process.env.REACT_APP_COGNITO_API_URL, {
      action,
      ...data
    });
    return response.data;
  } catch (error) {
    console.error(`Error performing action ${action}:`, error);
    throw error;
  }
}

export const signUp = async (email, password, firstName, lastName) => {
  try {
    const signUpResponse = await Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        given_name: firstName,
        family_name: lastName,
      }
    });
    return signUpResponse;
  } catch (error) {
    console.error('Error signing up:', error);
    throw error;
  }
};

export const confirmRegistration = async (email, verificationCode) => {
  try {
    const confirmSignUpResponse = await Auth.confirmSignUp(email, verificationCode);
    return confirmSignUpResponse;
  } catch (error) {
    console.error('Error confirming registration:', error);
    throw error;
  }
};

export async function associateSoftwareToken(email, password) {
  try {
    const user = await Auth.signIn(email, password);
    const data = await Auth.setupTOTP(user);
    const url = `otpauth://totp/AWSCognito:${user.getUsername()}?secret=${data}&issuer=${encodeURIComponent('RespondX')}`;

    return { url, secret: data };
  } catch (error) {
    console.log('Error setting up software token:', error);
    throw error;
  }
}

export async function setupSoftwareTokenMfa(user, code) {
  try {
    await Auth.verifyTotpToken(user, code);
    await Auth.setPreferredMFA(user, 'TOTP');
  } catch (error) {
    console.log('Error setting up MFA:', error);
    throw error;
  }
}

export const login = async (email, password) => {
  try {
    const user = await Auth.signIn(email, password);
    
    // Assuming you want to get the user's groups as part of the login process
    const userGroups = user.signInUserSession.accessToken.payload['cognito:groups'] || [];
    return { user, userGroups };
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
};

export const signOut = async () => {
  try {
    await Auth.signOut();
    localStorage.clear();
    sessionStorage.clear();
  } catch (error) {
    console.error('Error signing out:', error);
    throw error;
  }
};


export const getUserGroups = async (user) => {
  return makeApiRequest('getUserGroups', { user });
};

const getCurrentUser = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return user;
  } catch (error) {
    console.error('Error getting current user:', error);
    return null;
  }
};

export const isLoggedIn = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
    const session = await Auth.currentSession();
    const idToken = session.getIdToken();
    const accessToken = session.getAccessToken();
    const refreshToken = session.getRefreshToken();

    // Refresh the session if needed
    if (session.isValid() && refreshToken) {
      await Auth.currentSession(); // This will automatically refresh the session
    }

    // Extract user attributes
    const attributes = idToken.payload || {};
    const userGroups = accessToken.payload['cognito:groups'] || [];

    const firstName = attributes['given_name'] || null;
    const lastName = attributes['family_name'] || null;
    const email = attributes['email'] || null;
    const organization = attributes['custom:organization'] || null;

    return {
      isAuthenticated: true,
      attributes: attributes,
      groups: userGroups,
      firstName: firstName,
      lastName: lastName,
      email: email,
      organization: organization,
    };
  } catch (error) {
    console.error('Error checking login status:', error);
    return {
      isAuthenticated: false
    };
  }
};

export const forgotPassword = async (email) => {
  try {
    const data = await Auth.forgotPassword(email);
    return data;
  } catch (error) {
    console.error('Forgot password error:', error);
    throw error;
  }
};

export const confirmForgotPassword = async (email, verificationCode, newPassword) => {
  try {
    await Auth.forgotPasswordSubmit(email, verificationCode, newPassword);
  } catch (error) {
    console.error('Confirm forgot password error:', error);
    throw error;
  }
};

export const fetchUserOrganization = async () => {
  const cognitoUser = await getCurrentUser();

  if (!cognitoUser) {
    return null;
  }

  return new Promise((resolve, reject) => {
    cognitoUser.getSession((err, session) => {
      if (err) {
        reject(err);
        return;
      }

      cognitoUser.getUserAttributes((err, result) => {
        if (err) {
          reject(err);
          return;
        }

        const organizationAttr = result.find(attr => attr.getName() === 'custom:organization');
        const organization = organizationAttr ? organizationAttr.getValue() : null;
        resolve(organization);
      });
    });
  });
};

export const fetchUserOrganizationId = async () => {
  const cognitoUser = getCurrentUser();

  if (!cognitoUser) {
    return null;
  }

  return new Promise((resolve, reject) => {
    cognitoUser.getSession((err, session) => {
      if (err) {
        reject(err);
        return;
      }

      cognitoUser.getUserAttributes((err, result) => {
        if (err) {
          reject(err);
          return;
        }

        const organizationAttr = result.find(attr => attr.getName() === 'custom:orgId');
        const organization = organizationAttr ? organizationAttr.getValue() : null;
        resolve(organization);
      });
    });
  });
};

export const updateUserOrganization = async (organizationValue) => {
  const cognitoUser = getCurrentUser();
  if (!cognitoUser) {
    return null;
  }

  return new Promise((resolve, reject) => {
    cognitoUser.getSession(async (err, session) => {
      if (err) {
        reject(err);
        return;
      }

      const attributeList = [
        new CognitoUserAttribute({ Name: 'custom:organization', Value: organizationValue }),
      ];

      cognitoUser.updateAttributes(attributeList, (err, result) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(result);
      });
    });
  });
};

export const updateUserOrganizationId = async (org_id) => {
  const cognitoUser = getCurrentUser();

  if (!cognitoUser) {
    return null;
  }

  return new Promise((resolve, reject) => {
    cognitoUser.getSession(async (err, session) => {
      if (err) {
        reject(err);
        return;
      }

      const attributeList = [
        new CognitoUserAttribute({ Name: 'custom:orgId', Value: org_id.toString() }),
      ];

      cognitoUser.updateAttributes(attributeList, (err, result) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(result);
      });
    });
  });
};

export const addUserToGroup = async (groupName) => {
  const cognitoUser = getCurrentUser();
  if (!cognitoUser) {
    console.error('No user is currently logged in.');
    return;
  }

  cognitoUser.getSession(async (err, session) => {
    if (err) {
      console.error('Error getting session:', err);
      return;
    }

    const apiName = 'https://tfrh2bwb4b.execute-api.us-east-2.amazonaws.com';
    const path = '/staging/addusertocognitogroup';
    const requestBody = {
      cognitoUser: {
        accessToken: session.getIdToken().getJwtToken(),
        username: session.getIdToken().payload.email,
      },
      groupName,
    };
    const requestOptions = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await axios.post(apiName + path, requestBody, requestOptions);
    } catch (error) {
      console.error('Error calling API:', error);
    }
  });
};

export const getAccessToken = () => {
  const cognitoUser = getCurrentUser();

  if (!cognitoUser) {
    return null;
  }

  return new Promise((resolve, reject) => {
    cognitoUser.getSession((err, session) => {
      if (err) {
        reject(err);
        return;
      }
      const accessToken = session.getAccessToken().getJwtToken();
      resolve(accessToken);
    });
  });
};

export const listUsersInOrganization = async (organization) => {
  return makeApiRequest('listUsersInOrganization', { organization });
};

export const deleteUser = async (username) => {
  return makeApiRequest('deleteUser', { username });
};

export const disableUser = async (username) => {
  return makeApiRequest('disableUser', { username });
};

export const enableUser = async (username) => {
  return makeApiRequest('enableUser', { username });
};

export const addOrgUserToGroup = async (username, groupName) => {
  return makeApiRequest('addOrgUserToGroup', { username, groupName });
};

export const removeOrgUserFromGroup = async (username, groupName) => {
  return makeApiRequest('removeOrgUserFromGroup', { username, groupName });
};

export const listGroups = async () => {
  return makeApiRequest('listGroups');
};

export const updateUserAttributes = async (firstName, lastName) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const result = await Auth.updateUserAttributes(user, {
      'given_name': firstName,
      'family_name': lastName
    });
    return result;
  } catch (error) {
    console.error('Error updating user attributes:', error);
    throw error;
  }
};
