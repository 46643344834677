import axios from 'axios';

export const callFileParser = async (bucket, key, signedUrl) => {
    const api = process.env.REACT_APP_CALL_RESUME_GPT_API_URL;
    const params = {
        Records: [
            {
                s3: {
                    bucket: {
                        name: bucket
                    },
                    object: {
                        key: key,
                        url: signedUrl
                    }
                }
            }
        ]
    };

    try {
        const response = await axios.post(api, params);
        const responseBody = JSON.parse(response.data.body);
        const uniqueKey = responseBody.unique_key;
        return uniqueKey;
    } catch (error) {
        console.error(`Error calling file parser: `, error);
    }
};
