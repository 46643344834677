import React, { useState, useEffect, useRef } from 'react';
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter,
    useToast, Button, Input, VStack, FormControl, FormLabel, Textarea, Select, SimpleGrid,
    Divider, Spacer, Heading, Box, Text, Flex, Spinner, Progress
} from '@chakra-ui/react';
import { ResumeUploader } from '../../components/documentHandler';
import { MyPdfViewer } from '../../components/viewPdf';
import ChatGPTSandbox from '../../components/ChatGPTSandbox';

const ResumeReviewer = ({ isResumeModalOpen, setResumeModalOpen }) => {
    const [uploadedFile, setUploadedFile] = useState(null);
    const [uniqueKey, setUniqueKey] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [resumeData, setResumeData] = useState(null);
    const [showResumeChat, setShowResumeChat] = useState(false);
    const toast = useToast();
    const intervalId = useRef(null);
    const timeoutId = useRef(null);

    const resetData = () => {
        setUploadedFile(null);
        setUniqueKey(null);
        setIsLoading(false);
        setResumeData(null);

        // Clear interval and timeout if they exist
        if (intervalId) clearInterval(intervalId);
        if (timeoutId) clearTimeout(timeoutId);
    };

    {/* The below code is if we were to wait for the file to be parsed and saved to the database before showing the results

    const pollResumeData = async (uniqueKey) => {
        let isDataReceived = false;

        const fetchData = async () => {
            try {
                const response = await axios.post(process.env.REACT_APP_CANDIDATE_API_URL, {
                    action: 'pollResumeData',
                    //data: 'f9090c79-088d-4638-9e9f-cb8c14041790',
                    data: uniqueKey,
                });

                const parsedBody = JSON.parse(response.data.body);
                const parsedText = parsedBody.parsedText;
                console.log(parsedText);

                if (parsedText) {
                    setResumeData(parsedText);
                    isDataReceived = true;
                    clearInterval(intervalId.current);
                    clearTimeout(timeoutId.current);
                    setIsLoading(false);
                }

            } catch (error) {
                clearInterval(intervalId.current);
                clearTimeout(timeoutId.current);
                setIsLoading(false);
                console.error("Error polling resume data:", error);
            }
        };

        // Start polling immediately and set the interval
        fetchData();
        intervalId.current = setInterval(fetchData, 15000); // Poll every 15 seconds

        // Set a timeout for stopping the polling after 10 minutes
        timeoutId.current = setTimeout(() => {
            if (!isDataReceived) {
                clearInterval(intervalId.current);
                setIsLoading(false);
                toast({
                    title: "Processing File Failed",
                    description: "Please close the window and try again.",
                    status: "error",
                    duration: null,
                    isClosable: true,
                    position: "top",
                });
            }
        }, 10 * 60 * 1000); //Timeout after 10 minutes
    };

    useEffect(() => {
        if (uploadedFile && uniqueKey) {
            setIsLoading(true);
            pollResumeData(uniqueKey);
        }
        return () => {
            if (intervalId.current) clearInterval(intervalId.current);
            if (timeoutId.current) clearTimeout(timeoutId.current);
        };
    }, [uploadedFile, uniqueKey]);
    
        */}

    useEffect(() => {
        if (uploadedFile && uniqueKey) {
            setShowResumeChat(true);
        }
    }, [uploadedFile, uniqueKey]);

    return (
        <Modal isOpen={isResumeModalOpen} onClose={() => { resetData(); setResumeModalOpen(false) }} size={uploadedFile ? "full" : "4xl"}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader align="center">
                    <Heading size="lg">Review Resume</Heading>
                </ModalHeader>
                <ModalCloseButton />
                <Divider />
                <ModalBody>
                    {!uploadedFile ? (
                        <ResumeUploader
                            onUpload={setUploadedFile}
                            extractorData={(data) => {
                                setUniqueKey(data);
                            }}
                        />
                    ) : (
                        <Flex direction={["column", "column", "row"]} wrap="nowrap">
                            {uploadedFile && uploadedFile.extension === 'pdf' && (
                                <Box flex="1" maxWidth={["100%", "100%", "50%"]} overflow="auto" p={4}>
                                    <MyPdfViewer file={uploadedFile.url} />
                                </Box>
                            )}
                            <Box flex="1" maxWidth={["100%", "100%", "50%"]} overflow="auto" p={4}>
                                {isLoading ? (
                                    <Flex justifyContent="center" alignItems="center" mt={10}>
                                        <Box>
                                            <VStack>
                                                <Text>Processing File...</Text>
                                                <Spinner color="blue.500" size="xl" />
                                                <Progress size="md" isIndeterminate colorScheme="blue" />
                                            </VStack>
                                        </Box>
                                    </Flex>
                                ) : uploadedFile && resumeData ? (
                                    <>
                                        <div>
                                            {Object.entries(resumeData).map(([key, value]) => (
                                                <div key={key}>
                                                    <Heading size="md">{key}</Heading>
                                                    <ul>
                                                        {value.map((item, index) => (
                                                            <li key={index}>{item.keyword}</li> // Adjust this according to the structure of your data
                                                        ))}
                                                    </ul>
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                ) : uploadedFile && showResumeChat ? (
                                    <ChatGPTSandbox fileName={uploadedFile.fileName}/>
                                ) : null}
                            </Box>
                        </Flex>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button variant="ghost" mr={3} onClick={() => setResumeModalOpen(false)}>
                        Close
                    </Button>
                    {/*<Button colorScheme="blue">Submit</Button>*/}
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
export default ResumeReviewer;