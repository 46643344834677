import React, { useState, useEffect, useRef } from 'react';
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter,
    useToast, Button, Input, VStack, FormControl, FormLabel, Textarea, Select, SimpleGrid,
    Divider, Spacer, Heading, Box, Text, Flex, Spinner, Progress
} from '@chakra-ui/react';
import ChatGPTSandbox from '../../components/ChatGPTSandbox';


const ResumeChat = ({ isResumeChatModalOpen, setResumeChatModalOpen }) => {
    const [typing, setTyping] = useState(false);
    const [input, setInput] = useState('');
    const [previousInput, setPreviousInput] = useState('');
    const [messages, setMessages] = useState([]);

    const sendMessage = async () => {
        if (!input.trim()) return;

        const userMessage = { sender: 'user', text: input };
        setMessages([...messages, userMessage]);
        setPreviousInput(input);
        setInput('');

        const response = await fetch('/api/chat', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ message: input }),
        });

        const responseData = await response.json();

        const botMessage = { sender: 'bot', text: responseData.reply };
        setMessages(prev => [...prev, botMessage]);
    };

    const resendMessage = async () => {
        setInput(previousInput);
        await sendMessage();
    };

    const closeModal = () => {
        setResumeChatModalOpen(false);
        setMessages([]); 
        setInput('');
        setPreviousInput('');
    };

    return (
        <Modal isOpen={isResumeChatModalOpen} onClose={closeModal} size="4xl">
            <ModalOverlay />
            <ModalContent>
                <ModalBody p={8}>
            <ChatGPTSandbox />
            {/*
            
                
                    <VStack spacing={4}>
                        {messages.map((msg, index) => (
                            <Box key={index} alignSelf={msg.sender === 'user' ? 'flex-end' : 'flex-start'}>
                                <Text fontSize="md" p={2} bg={msg.sender === 'user' ? 'blue.100' : 'green.100'} borderRadius="md">
                                    {msg.text}
                                </Text>
                            </Box>
                        ))}
                        <Input
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
                            placeholder="Type your message here..."
                        />
                    </VStack>
                
                <ModalFooter>
                    <Button colorScheme="blue" onClick={sendMessage}>Send</Button>
                    {previousInput && (
                        <Button colorScheme="teal" mr={3} onClick={resendMessage}>
                            Resend
                        </Button>
                    )}
                    <Button colorScheme="gray" mr={3} onClick={closeModal}>
                        Close
                    </Button>
                </ModalFooter>
                */}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default ResumeChat;