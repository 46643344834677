import React, { useState } from "react";
import OpenAI from "openai";
import {
	Box,
	Flex,
	VStack,
	HStack,
	Text,
	InputGroup,
	Input,
	InputRightElement,
	IconButton,
	Button,
	Spacer
} from "@chakra-ui/react";
import { FiSend } from "react-icons/fi";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";

const openai = new OpenAI({
	apiKey: process.env.REACT_APP_OPENAI_API_KEY,
	apiBase: "http://localhost:8000/v1",
	dangerouslyAllowBrowser: true
});

const SuggestedPrompts = ({ suggestions }) => (
	<VStack spacing={2} mb={4} alignItems="flex-end">
		{suggestions.map((suggestion, i) => (
			<Button
				key={i}
				size="sm"
				variant="outline"
				colorScheme="blue"
				onClick={suggestion.onClick}
			>
				{suggestion.message}
			</Button>
		))}
	</VStack>
);

const ChatGPTSandbox = ({ fileName }) => {
	const [typing, setTyping] = useState(false);
	const [suggestionsVisible, setSuggestionsVisible] = useState(true);
	const [messages, setMessages] = useState([
		{
			message: "Hello, I am ResumeGPT",
			sender: "ChatGPT",
		},
	]);
	const suggestedPrompts = [
		{
			message: "Tell me about the candidate's experience",
			onClick: () => hdlSend("Tell me about the candidate's experience"),
		},
		{
			message: "What are the candidate's strengths and weaknesses?",
			onClick: () => hdlSend("What are the candidate's strengths and weaknesses?"),
		},
		{
			message: "Does the candidate have any relevant certifications?",
			onClick: () => hdlSend("Does the candidate have any relevant certifications?"),
		},
	];

	const hdlSend = async (msg) => {
		setSuggestionsVisible(false);
		const newMsg = {
			message: msg,
			sender: "user",
			direction: "outgoing",
		};
		const newMsgs = [...messages, newMsg];
		setMessages(newMsgs);
		setTyping(true);
		await sendToChatGPT(newMsgs);
	};

	const sendToChatGPT = async (chatMsgs) => {
		let apiMsgs = chatMsgs.map((msgObj) => {
			let role = msgObj.sender === "ChatGPT" ? "assistant" : "user";
			return { role, content: msgObj.message };
		});

		const systemMsg = {
			role: "system",
			content: "You are a top IT recruiting firm and will be discussing with me the resumes you have focusing on the one for a candidate in the file " + { fileName },
		};

		const apiRequestBody = {
			messages: fileName ? [systemMsg, ...apiMsgs] : [...apiMsgs],
			stream: false
		};

		try {
			// Fetch response from Canopy server
			const response = await fetch("http://localhost:8000/v1/chat/completions", {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(apiRequestBody)
			});

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			const completion = await response.json();
			let newMsg = completion.choices[0].message.content;

			// Update messages state
			setMessages([...chatMsgs, { message: newMsg, sender: "ChatGPT" }]);
		} catch (error) {
			console.error("Error fetching data: ", error);
		} finally {
			// Stop typing indicator
			setTyping(false);
		}
		{/*
		const apiRequestBody = {
			model: "gpt-3.5-turbo",
			messages: [...apiMsgs]
		};

		const completion = await openai.chat.completions.create(apiRequestBody);
		let newMsg = completion.choices[0].message.content;
		setMessages([...chatMsgs, { message: newMsg, sender: "ChatGPT" }]);
		setTyping(false);
	*/}
	};

	return (
		<Flex
			direction="column"
			height="85vh"
			justifyContent="flex-start"
			alignItems="center"
		>
			<VStack
				width="100%"
				maxWidth="800px"
				flexGrow={1}
				spacing={4}
				alignItems="stretch"
			>
				<Box
					flexGrow={1}
					display="flex" // Set as flex container
					flexDirection="column"
					overflowY="auto"
					borderWidth="1px"
					borderRadius="lg"
					p={4}
					boxShadow="base"
				>
					<VStack spacing={4} flexGrow={1} width="100%">
						{messages.map((msg, i) => (
							<Flex
								key={i}
								width="100%"
								justifyContent={msg.sender === "ChatGPT" ? "flex-start" : "flex-end"}
							>
								<Text
									p={3}
									borderRadius="md"
									bg={msg.sender === "ChatGPT" ? "gray.100" : "blue.100"}
									mb={3}
								>
									{msg.message}
								</Text>
							</Flex>
						))}

						<Spacer /> {/* Add Spacer here */}
						{typing && (
							<Text fontSize="sm" color="gray.500" alignSelf="flex-start">
								ResumeGPT is typing...
							</Text>
						)}
					</VStack>
					{suggestionsVisible && fileName && (
						<Box mt="auto">
							<SuggestedPrompts suggestions={suggestedPrompts} />
						</Box>
					)}
				</Box>
				<InputGroup size="md">
					<Input
						pr="4.5rem"
						placeholder="Type message here"
						onKeyPress={(e) => {
							if (e.key === "Enter" && !e.shiftKey) {
								hdlSend(e.currentTarget.value);
								e.currentTarget.value = "";
							}
						}}
					/>
					<InputRightElement width="4.5rem">
						<IconButton
							aria-label="Send message"
							variant="ghost"
							colorScheme="blue"
							size="sm"
							icon={<FiSend />}
							onClick={(e) => { hdlSend(e.currentTarget.value); }}
						/>
					</InputRightElement>
				</InputGroup>
			</VStack>

		</Flex>
	);
};

export default ChatGPTSandbox;