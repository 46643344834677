import React, { useEffect, useCallback, useState, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Image, Input, Text, Spinner, Flex, useColorMode, Icon, useToast } from '@chakra-ui/react';
import { FaUpload } from 'react-icons/fa';
import AWS from 'aws-sdk';
import axios from 'axios';
import { callFileParser } from './callFileParser';

const { REACT_APP_ACCESS_KEY_ID, REACT_APP_SECRET_ACCESS_KEY, REACT_APP_VERTEKAL_S3_BUCKET } = process.env;

const s3 = new AWS.S3({
  accessKeyId: REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: REACT_APP_SECRET_ACCESS_KEY,
  region: 'us-east-1'
});

export function listFiles(orgFolderId, oppFolder) {
  return new Promise((resolve, reject) => {
    const params = {
      Bucket: REACT_APP_VERTEKAL_S3_BUCKET,
      Prefix: `${orgFolderId}/${oppFolder}/`
    };

    s3.listObjectsV2(params, function (err, data) {
      if (err) {
        reject(err);
      } else {
        //console.log('Grabbed files from S3: ', data.Contents);
        // Here, for each file, we create a pre-signed URL and return an object with both the original file data and the URL.
        const filesWithSignedUrls = data.Contents.map(file => {
          const urlParams = {
            Bucket: REACT_APP_VERTEKAL_S3_BUCKET,
            Key: file.Key,
            Expires: 3600  // The URL will be valid for 1 hour
          };
          file.signedUrl = s3.getSignedUrl('getObject', urlParams);
          return file;
        });
        resolve(filesWithSignedUrls);
      }
    });
  });
};

export function listAllFiles(orgFolderId, folderPrefix = '') {
  return new Promise((resolve, reject) => {
    const params = {
      Bucket: REACT_APP_VERTEKAL_S3_BUCKET,
      Prefix: `${orgFolderId}${folderPrefix ? '/' + folderPrefix : ''}/`
    };

    s3.listObjectsV2(params, function (err, data) {
      if (err) {
        reject(err);
      } else {
        if (data.Contents && Array.isArray(data.Contents)) {
          const filesWithSignedUrls = data.Contents.map(file => {
            const urlParams = {
              Bucket: REACT_APP_VERTEKAL_S3_BUCKET,
              Key: file.Key,
              Expires: 3600
            };
            file.signedUrl = s3.getSignedUrl('getObject', urlParams);
            return file;
          });
          resolve(filesWithSignedUrls);
        } else {
          reject(new Error("Invalid data format received from the API"));
        }
      }
    });
  });
};


export function uploadFile(file) {
  return new Promise((resolve, reject) => {
    const params = {
      Bucket: REACT_APP_VERTEKAL_S3_BUCKET,
      Key: `resumes/${file.name}`,
      Body: file
    };
    s3.upload(params)
      .promise()
      .then(data => {
        //console.log(`File uploaded successfully at ${data.Location}`);
        const extension = file.name.split('.').pop();
        resolve({ location: data.Location, extension });
      })
      .catch(err => {
        console.error(`Error uploading file: ${err}`);
        reject(err);
      });
  });
};

export function deleteFile(orgFolderId, oppFolder, file) {
  return new Promise((resolve, reject) => {
    const params = {
      Bucket: REACT_APP_VERTEKAL_S3_BUCKET,
      Key: `resumes/${file.name}`
    };

    s3.deleteObject(params, function (err, data) {
      if (err) {
        console.error(`Error deleting file: ${err}`);
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
};

export function getSignedUrl(filePath) {
  return new Promise((resolve, reject) => {
    const urlParams = {
      Bucket: REACT_APP_VERTEKAL_S3_BUCKET,
      Key: filePath,
      Expires: 3600  // The URL will be valid for 1 hour
    };
    try {
      const signedUrl = s3.getSignedUrl('getObject', urlParams);
      resolve(signedUrl);
    } catch (err) {
      reject(err);
      console.log(err);
    }
  });
};

export function ResumeUploader({ onUpload, extractorData }) {
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState('');
  const [fileExtension, setFileExtension] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  
  useEffect(() => {
    if (file) {
      setIsUploading(true);
      uploadFile(file)
        .then(({ location, extension }) => {
          const urlParams = {
            Bucket: REACT_APP_VERTEKAL_S3_BUCKET,
            Key: `resumes/${file.name}`,
            Expires: 3600  // The URL will be valid for 1 hour
          };
          const signedUrl = s3.getSignedUrl('getObject', urlParams);
          onUpload({ url: signedUrl, extension, fileName: file.name });
          setFile(null);
          setPreview(location);
          setFileExtension(extension);
          setIsUploading(false);

          const s3Key = `resumes/${file.name}`;

          callFileParser(REACT_APP_VERTEKAL_S3_BUCKET, s3Key, signedUrl)
            .then(data => {
              extractorData(data);
            })
            .catch(err => console.error(`Error calling file parser: ${err}`));


        })
        .catch(err => {
          console.error(`Error uploading file: ${err}`);
          setIsUploading(false);
        });
    }
  }, [file, onUpload, extractorData]);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    localStorage.setItem('fileName', file.name);
    setFile(file);
    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Box
      {...getRootProps()}
      height="lg"
      borderWidth={2}
      borderRadius="md"
      borderStyle="dashed"
      borderColor="gray.500"
      textAlign="center"
      mt={4}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Input {...getInputProps()} display="none" />
      {
        isDragActive ?
          <Text>Drop the files here ...</Text> :
          <Text>Drag and drop resume here to upload, or click to select files</Text>
      }
      {file && <Box><Text as="strong">Selected file:</Text> {file.path}</Box>}
      {isUploading ? <Spinner /> : (preview && fileExtension !== 'pdf' && <Image src={preview} alt="preview" />)}
    </Box>
  )
};


export function uploadLibraryFile(orgFolderId, docType, file) {
  return new Promise((resolve, reject) => {
    const params = {
      Bucket: REACT_APP_VERTEKAL_S3_BUCKET,
      Key: `${orgFolderId}/${docType}/${file.name}`,
      Body: file
    };
    s3.upload(params)
      .promise()
      .then(data => {
        //console.log(`File uploaded successfully at ${data.Location}`);
        const extension = file.name.split('.').pop();
        resolve({ location: data.Location, extension });
      })
      .catch(err => {
        console.error(`Error uploading file: ${err}`);
        reject(err);
      });
  });
};

export function DocumentLibraryUploader({ orgFolderId, docType, orgId, onUpload, extractorData }) {
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState('');
  const [fileExtension, setFileExtension] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const { colorMode } = useColorMode();
  const toast = useToast();

  useEffect(() => {
    if (orgFolderId && file) {
      if (!docType) {
        toast({
          title: "Document Type Required",
          description: "Please select a document type before uploading.",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "bottom-left",
        });
        return;
      }
      setIsUploading(true);
      uploadLibraryFile(orgFolderId, docType, file)
        .then(({ location, extension }) => {
          const urlParams = {
            Bucket: REACT_APP_VERTEKAL_S3_BUCKET,
            Key: `${orgFolderId}/${docType}/${file.name}`,
            Expires: 3600  // The URL will be valid for 1 hour
          };
          const signedUrl = s3.getSignedUrl('getObject', urlParams);
          onUpload({ url: signedUrl, extension });
          const s3Key = `${orgFolderId}/${docType}/${file.name}`;

          setFile(null);
          setPreview(location);
          setFileExtension(extension);
          setIsUploading(false);
          writeDocuments({ docType, fileName: file.name, orgId });
          toast({
            title: "File Uploaded",
            description: "Your file was uploaded successfully.",
            status: "success",
            duration: 9000,
            isClosable: true,
            position: "bottom-left",
          });
        })
        .catch(err => {
          console.error(`Error uploading file: ${err}`);
          setIsUploading(false);
        });
    }
  }, [orgFolderId, docType, file, onUpload, extractorData, toast]);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    localStorage.setItem('fileName', file.name);
    setFile(file);
    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Flex
      {...getRootProps()} height="250px" width="1000px" border="2px dashed gray" borderRadius="md" alignItems="center" justifyContent="center" cursor="pointer" bg={colorMode === 'light' ? 'white' : 'gray.700'}>
      <Input {...getInputProps()} />
      {
        isUploading ?
          <Flex direction="column" alignItems="center">
            <Spinner size="xl" />
            <Text mt={2}>Uploading...</Text>
          </Flex>
          :
          (
            isDragActive ?
              <Text>Drop the files here ...</Text> :
              <Flex>
                <Icon as={FaUpload} w={8} h={8} />
                <Text ml={2}>Drag & drop files here, or click to select files</Text>
              </Flex>
          )
      }
    </Flex>
  )
};

const writeDocuments = async ({ docType, fileName, orgId }) => {
  const user = localStorage.getItem('userEmail');
  try {
    const response = await axios.post(process.env.REACT_APP_DOCUMENTS_API_URL, {
      action: 'writeDocument',
      data: { docType, fileName, orgId, user }
    });

  } catch (err) {
    console.error(`Error writing documents: ${err}`);
  }

};
