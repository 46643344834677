import React, { useState, useRef } from 'react';
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter,
    useToast, Button, Input, VStack, FormControl, FormLabel, Textarea, Select, SimpleGrid,
    Divider, Spacer, Heading, AlertDialog, AlertDialogBody, AlertDialogFooter,
    AlertDialogHeader, AlertDialogOverlay, AlertDialogContent, AlertDialogCloseButton,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { Formik, Form, Field, ErrorMessage, useFormik } from 'formik';
import ReactSelect from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as Yup from 'yup';
import axios from 'axios';

const EditCandidate = ({ isEditModalOpen, setEditModalOpen, candidate, onCandidateUpdated }) => {
    const toast = useToast();
    const user = localStorage.getItem('userEmail');
    const initialAvailableDate = candidate.availability ? new Date(candidate.availability) : new Date();
    const initialSelectedDate = candidate.last_contacted ? new Date(candidate.last_contacted) : new Date();
    const [availableDate, setAvailableDate] = useState(initialAvailableDate);
    const [selectedDate, setSelectedDate] = useState(initialSelectedDate);
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
    const cancelRef = useRef();
    const openDeleteAlert = () => setIsDeleteAlertOpen(true);
    const closeDeleteAlert = () => setIsDeleteAlertOpen(false);
    const formatSalaryForDisplay = (salary) => salary ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(salary) : '';
    const [rawSalaryValue, setRawSalaryValue] = useState(candidate.expected_salary ? candidate.expected_salary.toString() : '');
    const [displaySalaryValue, setDisplaySalaryValue] = useState(formatSalaryForDisplay(candidate.expected_salary));
    const [specialtyValues, setSpecialtyValues] = useState(candidate.specialties);
    const [certificationValues, setCertificationValues] = useState(candidate.certifications);

    const initialValues = {
        id: candidate.id,
        status: candidate.status,
        name: candidate.name,
        email: candidate.email,
        phone: candidate.phone,
        position: candidate.position,
        notes: candidate.notes,
        nextSteps: candidate.next_steps,
        expectedSalary: candidate.expected_salary,
        seniority: candidate.seniority,
        specialties: candidate.specialties,
        certifications: candidate.certifications,
        availability: availableDate,
        lastContacted: selectedDate,
        linkedIn: '',
        resume: null,
        education: candidate.education
    };

    const validationSchema = Yup.object().shape({
        status: Yup.string().required('Status is required'),
        name: Yup.string().required('Name is required'),
        email: Yup.string().email('Invalid email').required('Email is required'),
        phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
        position: Yup.string().required('Position is required'),
        notes: Yup.string(),
        nextSteps: Yup.string(),
        expectedSalary: Yup.number().nullable().positive('Expected salary must be positive'),
        seniority: Yup.string(),
        specialties: Yup.array(),
        certifications: Yup.array(),
        availability: Yup.string(),
        linkedIn: Yup.string().url('Invalid URL'),
        education: Yup.string().required('Education level is required')
        //resume: Yup.mixed()
    });

    const onSubmit = async (values, { setSubmitting, resetForm }) => {
        values.expectedSalary = parseFloat(displaySalaryValue.replace(/[^0-9.]/g, ''));
        values.specialties = specialtyValues;
        values.certifications = certificationValues;
        try {
            const response = await axios.post(process.env.REACT_APP_CANDIDATE_API_URL, {
                action: 'updateById',
                id: candidate.id,
                data: values,
                user: user,
            });

            if (response.data.statusCode === 200) {
                onCandidateUpdated();
                toast({
                    title: "Candidate Updated",
                    position: "top",
                    description: "Candidate has been updated successfully",
                    status: "success",
                    duration: 5000,
                    isClosable: true
                });
                setEditModalOpen(false);
                resetForm();
            } else {
                toast({
                    title: "Error",
                    position: "top",
                    description: "An error occurred while updating the candidate",
                    status: "error",
                    duration: 5000,
                    isClosable: true
                });
            }
        } catch (err) {
            console.log(err);
            toast({
                title: "Error",
                position: "top",
                description: "An error occurred while updating the candidate",
                status: "error",
                duration: 5000,
                isClosable: true
            });
        } finally {
            setSubmitting(false);
        }
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnMount: false,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit,

    });

    const PhoneInput = ({ field, form, ...props }) => {
        const formatPhoneNumber = (val) => {
            if (!val) return val;
            const phoneNumber = val.replace(/[^\d]/g, '');
            if (phoneNumber.length < 4) return phoneNumber;
            if (phoneNumber.length < 7) {
                return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
            }
            return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
        };

        const handleChange = (e) => {
            const formattedPhoneNumber = formatPhoneNumber(e.target.value);
            form.setFieldValue(field.name, formattedPhoneNumber);
        };

        return (
            <Input
                {...field}
                {...props}
                value={formatPhoneNumber(field.value)}
                onChange={handleChange}
            />
        );
    };

    const handleSalaryChange = (e) => {
        const value = e.target.value.replace(/[^0-9.]/g, '');
        setRawSalaryValue(value);
        formik.setFieldValue('expectedSalary', value ? parseFloat(value) : '');
    };
    
    const handleSalaryBlur = (e) => {
        formik.handleBlur(e);
        if (formik.values.expectedSalary) {
            setDisplaySalaryValue(new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(formik.values.expectedSalary));
        } else {
            setDisplaySalaryValue('');
        }
        setRawSalaryValue('');
    };
    
    const handleSalaryFocus = () => {
        setRawSalaryValue(candidate.expected_salary ? formik.values.expectedSalary.toString() : '');
        setDisplaySalaryValue('');
    };

    const handleDeleteCandidate = async () => {
        closeDeleteAlert();
        try {
            const response = await axios.post(process.env.REACT_APP_CANDIDATE_API_URL, {
                action: 'deleteById',
                id: candidate.id
            });

            if (response.data.statusCode === 200) {
                onCandidateUpdated();
                toast({
                    title: "Candidate Deleted",
                    position: "top",
                    description: "Candidate has been deleted successfully",
                    status: "success",
                    duration: 5000,
                    isClosable: true
                });
                setEditModalOpen(false);
            } else {
                toast({
                    title: "Error",
                    position: "top",
                    description: "An error occurred while deleting the candidate",
                    status: "error",
                    duration: 5000,
                    isClosable: true
                });
            }
        } catch (err) {
            console.log(err);
            toast({
                title: "Error",
                position: "top",
                description: "An error occurred while deleting the candidate",
                status: "error",
                duration: 5000,
                isClosable: true
            });
        }
    };

    return (
        <>
            <Modal isOpen={isEditModalOpen} onClose={() => setEditModalOpen(false)} size="6xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader align="center">
                        <Heading size="lg">Update Candidate: {candidate.name}</Heading>
                    </ModalHeader>
                    <ModalCloseButton />
                    <Divider />
                    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                        {({ isSubmitting, isValid, dirty, touched, values, errors, setFieldValue }) => (
                            <Form>
                                <ModalBody>
                                    <SimpleGrid columns={2} spacing={4}>
                                        {/* Status Field */}
                                        <FormControl isRequired>
                                            <FormLabel htmlFor="status">Status</FormLabel>
                                            <Field
                                                as={Select}
                                                name="status"
                                                placeholder="Select status"
                                                borderColor={touched.status && errors.status ? 'red.500' : 'gray.200'}
                                                borderWidth={touched.status && errors.status ? '2px' : '1px'}
                                            >
                                                <option value="Application Received">Application Received</option>
                                                <option value="Under Review">Under Review</option>
                                                <option value="Shortlisted">Shortlisted</option>
                                                <option value="Interview Scheduled">Interview Scheduled</option>
                                                <option value="Interview Completed">Interview Completed</option>
                                                <option value="Background Check">Background Check</option>
                                                <option value="Offer Extended">Offer Extended</option>
                                                <option value="Contingent Offer Extended">Contingent Offer Extended</option>
                                                <option value="Offer Accepted">Offer Accepted</option>
                                                <option value="Contingent Offer Accepted">Contingent Offer Accepted</option>
                                                <option value="Pending Clearance Crossover">Pending Clearance Crossover</option>
                                                <option value="Offer Declined">Offer Declined</option>
                                                <option value="On Hold">On Hold</option>
                                                <option value="Rejected">Rejected</option>
                                                <option value="Hired">Hired</option>
                                                <option value="Withdrawn">Withdrawn</option>
                                            </Field>
                                            <ErrorMessage name="status" component="div" className="error-message" />
                                        </FormControl>

                                        {/* Full Name Field */}
                                        <FormControl isRequired>
                                            <FormLabel htmlFor="name">Full Name</FormLabel>
                                            <Field name="name" as={Input} />
                                            <ErrorMessage name="name" component="div" className="error-message" />
                                        </FormControl>

                                        {/* Email Address Field */}
                                        <FormControl isRequired>
                                            <FormLabel htmlFor="email">Email Address</FormLabel>
                                            <Field name="email" type="email" as={Input} />
                                            <ErrorMessage name="email" component="div" className="error-message" />
                                        </FormControl>

                                        {/* Phone Number Field */}
                                        <FormControl>
                                            <FormLabel htmlFor="phone">Phone Number</FormLabel>
                                            <Field name="phone">
                                                {({ field, form, meta }) => (
                                                    <PhoneInput
                                                        field={field}
                                                        form={form}
                                                        borderColor={meta.touched && meta.error ? 'red.500' : 'gray.200'}
                                                        borderWidth={meta.touched && meta.error ? '2px' : '1px'}
                                                    />
                                                )}
                                            </Field>
                                            <ErrorMessage name="phone" component="div" className="error-message" />
                                        </FormControl>

                                        {/* Seniority Field */}
                                        <FormControl isRequired>
                                            <FormLabel htmlFor="seniority">Seniority</FormLabel>
                                            <Field
                                                as={Select}
                                                name="seniority"
                                                placeholder="Select seniority level"
                                                borderColor={touched.seniority && errors.seniority ? 'red.500' : 'gray.200'}
                                                borderWidth={touched.seniority && errors.seniority ? '2px' : '1px'}
                                            >
                                                <option value="Junior (0-5 years)">Junior (0-5 years)</option>
                                                <option value="Mid-level (5-10 years)">Mid-level (5-10 years)</option>
                                                <option value="Senior (10+ years)">Senior (10+ years)</option>
                                            </Field>
                                            <ErrorMessage name="seniority" component="div" className="error-message" />
                                        </FormControl>

                                        <FormControl isRequired>
                                            <FormLabel htmlFor="education">Education Level</FormLabel>
                                            <Field
                                                as={Select}
                                                name="education"
                                                placeholder="Select education level"
                                                borderColor={touched.position && errors.position ? 'red.500' : 'gray.200'}
                                                borderWidth={touched.position && errors.position ? '2px' : '1px'}
                                            >
                                                <option value="High School Diploma">High School Diploma</option>
                                                <option value="Bacehlor's in IT field">Bacehlor's in IT field</option>
                                                <option value="Bachelor's in Other">Bachelor's in Other</option>
                                                <option value="Master's in IT field">Master's in IT field</option>
                                                <option value="Master's in Other">Master's in Other</option>
                                                <option value="Doctorate in IT field">Doctorate in IT field</option>
                                                <option value="Doctorate in Other">Doctorate in Other</option>
                                            </Field>
                                            <ErrorMessage name="education" />
                                        </FormControl>

                                        {/* Position Field */}
                                        <FormControl isRequired>
                                            <FormLabel htmlFor="position">Position</FormLabel>
                                            <Field
                                                as={Select}
                                                name="position"
                                                placeholder="Select position"
                                                borderColor={touched.position && errors.position ? 'red.500' : 'gray.200'}
                                                borderWidth={touched.position && errors.position ? '2px' : '1px'}
                                            >
                                                <option value="Systems Engineer">Systems Engineer</option>
                                                <option value="Software Developer">Software Developer</option>
                                                <option value="Infrastructure Engineer">Infrastructure Engineer</option>
                                                <option value="Systems Administrator">Systems Administrator</option>
                                                <option value="Cyber Security Engineer">Cyber Security Engineer</option>
                                                <option value="Data Scientist">Data Scientist</option>
                                                <option value="Information Assurance Engineer">Information Assurance Engineer</option>
                                            </Field>
                                            <ErrorMessage name="position" />
                                        </FormControl>

                                        {/* Expected Salary Field */}
                                        <FormControl>
                                            <FormLabel htmlFor="expectedSalary">Expected Salary</FormLabel>
                                            <Input
                                                name="expectedSalary"
                                                value={displaySalaryValue || rawSalaryValue}
                                                onChange={handleSalaryChange}
                                                onBlur={handleSalaryBlur}
                                                onFocus={handleSalaryFocus}
                                            />
                                            <ErrorMessage name="expectedSalary" component="div" className="error-message" />
                                        </FormControl>

                                        {/* LinkedIn Profile Field */}
                                        <FormControl>
                                            <FormLabel htmlFor="linkedIn">LinkedIn Profile</FormLabel>
                                            <Field name="linkedIn" as={Input} />
                                            <ErrorMessage name="linkedIn" component="div" className="error-message" />
                                        </FormControl>

                                        {/* Specialties Field */}
                                        <FormControl>
                                            <FormLabel htmlFor="specialties">Specialties</FormLabel>
                                            <ReactSelect
                                                isMulti
                                                options={[
                                                    { value: 'Linux Administration', label: 'Linux Administration' },
                                                    { value: 'Windows Administration', label: 'Windows Administration' },
                                                    { value: 'Python Development', label: 'Python Development' },
                                                    { value: 'Java Development', label: 'Java Development' },
                                                    { value: 'AWS Cloud Infrastructure', label: 'AWS Cloud Infrastructure' },
                                                    { value: 'Azure Cloud Infrastructure', label: 'Azure Cloud Infrastructure' },
                                                    { value: 'GCP Cloud Infrastructure', label: 'GCP Cloud Infrastructure' },
                                                    { value: 'Cyber Security', label: 'Cyber Security' },
                                                    { value: 'Data Science', label: 'Data Science' },
                                                    { value: 'Machine Learning', label: 'Machine Learning' },
                                                    { value: 'Artificial Intelligence', label: 'Artificial Intelligence' },
                                                    { value: 'DevOps', label: 'DevOps' },
                                                    { value: 'Full Stack Development', label: 'Full Stack Development' },
                                                    { value: 'Front End Development', label: 'Front End Development' },
                                                    { value: 'Back End Development', label: 'Back End Development' },
                                                    { value: 'Database Administration', label: 'Database Administration' },
                                                    { value: 'Networking', label: 'Networking' }
                                                ]}
                                                placeholder="Select Specialties"
                                                name="specialties"
                                                onChange={(selectedOptions) => {
                                                    const values = selectedOptions ? selectedOptions.map(option => option.value) : [];
                                                    setSpecialtyValues(values);
                                                }}
                                                onBlur={formik.handleBlur}
                                                value={specialtyValues.map(value => ({ label: value, value: value }))}
                                            />
                                            <ErrorMessage name="specialties" />
                                        </FormControl>

                                        {/* Certifications Field */}
                                        <FormControl>
                                            <FormLabel htmlFor="certifications">Certifications</FormLabel>
                                            <ReactSelect
                                                isMulti
                                                options={[
                                                    { value: 'CISSP', label: 'Certified Information Systems Security Professional (CISSP)' },
                                                    { value: 'CEH', label: 'Certified Ethical Hacker (CEH)' },
                                                    { value: 'CISM', label: 'Certified Information Security Manager (CISM)' },
                                                    { value: 'CompTIA Security+', label: 'CompTIA Security+' },
                                                    { value: 'CCNA', label: 'Cisco Certified Network Associate (CCNA)' },
                                                    { value: 'CCNP', label: 'Cisco Certified Network Professional (CCNP)' },
                                                    { value: 'GCIH', label: 'GIAC Certified Incident Handler (GCIH)' },
                                                    { value: 'GPEN', label: 'GIAC Penetration Tester (GPEN)' },
                                                    { value: 'GWAPT', label: 'GIAC Web Application Penetration Tester (GWAPT)' },
                                                    { value: 'AWS Certified Solutions Architect', label: 'AWS Certified Solutions Architect' },
                                                    { value: 'AWS Certified Cloud Practitioner', label: 'AWS Certified Cloud Practitioner (CCP)' },
                                                    { value: 'Azure Certified Solutions Architect', label: 'Microsoft Certified: Azure Solutions Architect Expert' },
                                                    { value: 'Microsoft Certified: Azure Fundamentals', label: 'Microsoft Certified: Azure Fundamentals (AZ-900)' },
                                                    { value: 'GCP Professional Cloud Architect', label: 'Google Certified Professional Cloud Architect' },
                                                    { value: 'Google Associate Cloud Engineer', label: 'Associate Cloud Engineer (Google Cloud)' },
                                                    { value: 'Linux Professional Institute Certification', label: 'Linux Professional Institute Certification (LPIC)' },
                                                    { value: 'Red Hat Certified Engineer', label: 'Red Hat Certified Engineer (RHCE)' },
                                                    { value: 'Certified ScrumMaster', label: 'Certified ScrumMaster (CSM)' },
                                                    { value: 'Project Management Professional', label: 'Project Management Professional (PMP)' },
                                                    { value: 'ITIL', label: 'Information Technology Infrastructure Library (ITIL)' },
                                                    { value: 'ISACA', label: 'Information Systems Audit and Control Association (ISACA)' },
                                                    { value: 'CompTIA Advanced Security Practitioner', label: 'CompTIA Advanced Security Practitioner (CASP+)' },
                                                    { value: 'Offensive Security Certified Professional', label: 'Offensive Security Certified Professional (OSCP)' },
                                                    { value: 'Certified Data Professional', label: 'Certified Data Professional (CDP)' },
                                                    { value: 'Certified Information Systems Auditor', label: 'Certified Information Systems Auditor (CISA)' },
                                                    { value: 'Certified Cloud Security Professional', label: 'Certified Cloud Security Professional (CCSP)' }
                                                ]}                                                                                               
                                                placeholder="Select certifications"
                                                name="certifications"
                                                onChange={(selectedOptions) => {
                                                    const values = selectedOptions ? selectedOptions.map(option => option.value) : [];
                                                    setCertificationValues(values);
                                                }}
                                                onBlur={formik.handleBlur}
                                                value={certificationValues.map(value => ({ label: value, value: value }))}
                                            />
                                            <ErrorMessage name="certifications" />
                                        </FormControl>

                                        <FormControl>
                                        </FormControl>

                                        {/* Notes Field */}
                                        <FormControl>
                                            <FormLabel htmlFor="notes">Notes</FormLabel>
                                            <Field name="notes" as={Textarea} />
                                            <ErrorMessage name="notes" component="div" className="error-message" />
                                        </FormControl>

                                        {/* Next Steps Field */}
                                        <FormControl>
                                            <FormLabel htmlFor="nextSteps">Next Steps</FormLabel>
                                            <Field name="nextSteps" as={Textarea} />
                                            <ErrorMessage name="nextSteps" component="div" className="error-message" />
                                        </FormControl>

                                        {/* Availability Field */}
                                        <FormControl>
                                            <FormLabel htmlFor="availability">Availability</FormLabel>
                                            <DatePicker
                                                selected={availableDate}
                                                onChange={(date) => {
                                                    setAvailableDate(date);
                                                    setFieldValue('availability', date.toISOString());
                                                }}
                                            />
                                            <ErrorMessage name="availability" component="div" className="error-message" />
                                        </FormControl>

                                        <FormControl>
                                            <FormLabel htmlFor="lastContacted">Last Contacted</FormLabel>
                                            <DatePicker
                                                selected={selectedDate}
                                                onChange={(date) => {
                                                    setSelectedDate(date);
                                                    setFieldValue('lastContacted', date.toISOString());
                                                }}
                                            />
                                            <ErrorMessage name="lastContacted" component="div" className="error-message" />
                                        </FormControl>


                                        {/* Resume Upload (if applicable) */}
                                        {/* ... */}
                                    </SimpleGrid>
                                </ModalBody>
                                <ModalFooter>
                                    <Button ml={3} colorScheme='red' leftIcon={<DeleteIcon />} onClick={openDeleteAlert}>Delete Candidate</Button>
                                    <Spacer />
                                    <Button
                                        type="submit"
                                        isLoading={isSubmitting}
                                        colorScheme="blue"
                                        isDisabled={!isValid}
                                    >
                                        Update Candidate
                                    </Button>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </ModalContent>
            </Modal>

            <AlertDialog
                isOpen={isDeleteAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={closeDeleteAlert}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Delete Candidate
                        </AlertDialogHeader>
                        <AlertDialogCloseButton />
                        <AlertDialogBody>
                            Are you sure you want to delete this candidate? This action cannot be undone.
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={closeDeleteAlert}>
                                Cancel
                            </Button>
                            <Button colorScheme="red" onClick={handleDeleteCandidate} ml={3}>
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
};

export default EditCandidate;