import React, { useState, useEffect, useMemo } from 'react';
import {
    Box, Table, Thead, Tbody, Tr, Th, Td, IconButton, Flex, Text, Input, InputGroup, InputLeftElement, Select, Button
} from '@chakra-ui/react';
import { EditIcon, ChevronLeftIcon, ChevronRightIcon, SearchIcon } from '@chakra-ui/icons';
import ReactSelect from 'react-select';

const CandidateList = ({ candidates, onEdit }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedPositions, setSelectedPositions] = useState([]);
    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [totalPages, setTotalPages] = useState(Math.ceil(candidates.length / itemsPerPage));
    const positionOptions = useMemo(() => Array.from(new Set(candidates.map(candidate => candidate.position)))
        .map(position => ({ value: position, label: position })), [candidates]);
    const [sortConfig, setSortConfig] = useState({ key: 'last_contacted', direction: 'descending' });
    const allStatusOptions = [
        { value: 'Application Received', label: 'Application Received' },
        { value: 'Under Review', label: 'Under Review' },
        { value: 'Shortlisted', label: 'Shortlisted' },
        { value: 'Interview Scheduled', label: 'Interview Scheduled' },
        { value: 'Interview Completed', label: 'Interview Completed' },
        { value: 'Background Check', label: 'Background Check' },
        { value: 'Offer Extended', label: 'Offer Extended' },
        { value: 'Contingent Offer Extended', label: 'Contingent Offer Extended' },
        { value: 'Offer Accepted', label: 'Offer Accepted' },
        { value: 'Contingent Offer Accepted', label: 'Contingent Offer Accepted' },
        { value: 'Pending Clearance Crossover', label: 'Pending Clearance Crossover' },
        { value: 'Offer Declined', label: 'Offer Declined' },
        { value: 'On Hold', label: 'On Hold' },
        { value: 'Rejected', label: 'Rejected' },
        { value: 'Hired', label: 'Hired' },
        { value: 'Withdrawn', label: 'Withdrawn' }
    ];
    const activeStatuses = [
        'Interview Scheduled', 'Interview Completed', 'Background Check',
        'Offer Extended', 'Contingent Offer Extended', 'Offer Accepted',
        'Contingent Offer Accepted', 'Pending Clearance Crossover'
    ];
    const statusOptions = allStatusOptions;

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
        setCurrentPage(1); // Reset page when search term changes
    };

    const filteredCandidates = useMemo(() => candidates.filter(candidate =>
        (selectedPositions.length === 0 || selectedPositions.some(pos => pos.value === candidate.position)) &&
        (selectedStatuses.length === 0 || selectedStatuses.some(status => status.value === candidate.status)) &&
        (searchTerm === '' || candidate.name.toLowerCase().includes(searchTerm) || candidate.email.toLowerCase().includes(searchTerm))
    ), [candidates, selectedPositions, selectedStatuses, searchTerm]);

    useEffect(() => {
        const newTotalPages = Math.ceil(filteredCandidates.length / itemsPerPage);
        if (totalPages !== newTotalPages) {
            // If totalPages is a state setter function from parent
            setTotalPages(newTotalPages);
        }
    }, [filteredCandidates, totalPages]);

    const handleFilterChange = (setFilterFunction) => (newFilterValue) => {
        setFilterFunction(newFilterValue);
        setCurrentPage(1); // Reset to first page on filter change
    };

    const paginatedCandidates = useMemo(() => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        return filteredCandidates.slice(startIndex, startIndex + itemsPerPage);
    }, [currentPage, filteredCandidates]);

    const handleColumnClick = (column) => {
        let direction = 'ascending';
        if (
            sortConfig.key === column &&
            sortConfig.direction === 'ascending'
        ) {
            direction = 'descending';
        }
        setSortConfig({ key: column, direction });
    };

    const sortedCandidates = React.useMemo(() => {
        let sortableItems = [...filteredCandidates];
        if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
                let aVal = a[sortConfig.key];
                let bVal = b[sortConfig.key];

                // If sorting by date, convert to timestamp for comparison
                if (sortConfig.key === 'last_contacted') {
                    aVal = new Date(aVal).getTime();
                    bVal = new Date(bVal).getTime();
                }

                if (aVal < bVal) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (aVal > bVal) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [filteredCandidates, sortConfig]);

    const isActiveStatusesSelected = () => {
        if (selectedStatuses.length !== activeStatuses.length) {
            return false;
        }
        return selectedStatuses.every(status => activeStatuses.includes(status.value));
    };

    return (
        <Box overflowX="auto">
            <Flex mb={4}>
                <InputGroup mb={4}>
                    <InputLeftElement
                        pointerEvents="none"
                        children={<SearchIcon color="gray.300" />}
                    />
                    <Input
                        type="search"
                        placeholder="Search candidates..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        maxW="500px"
                        bg="white"
                    />
                </InputGroup>
                <Button
                    colorScheme={isActiveStatusesSelected() ? "red" : "blue"}
                    w="200px"
                    mr={4}
                    onClick={() => {
                        if (isActiveStatusesSelected()) {
                            setSelectedStatuses([]);
                        } else {
                            const activeOptions = allStatusOptions.filter(option =>
                                activeStatuses.includes(option.value)
                            );
                            setSelectedStatuses(activeOptions);
                        }
                    }}
                >
                    {isActiveStatusesSelected() ? "Hide Active" : "Show Active"}
                </Button>
                <ReactSelect
                    isMulti
                    options={positionOptions}
                    placeholder="Filter by positions"
                    onChange={handleFilterChange(setSelectedPositions)}
                    value={selectedPositions}
                    styles={{
                        control: (base) => ({
                            ...base,
                            width: '300px',
                            marginLeft: '2px',
                            marginRight: '4px',
                        }),
                        menuList: (provided) => ({
                            ...provided,
                            maxHeight: '200px',
                            overflowY: 'auto'
                        }),
                        multiValue: (base) => ({
                            ...base,
                        }),
                        valueContainer: (base) => ({
                            ...base,
                            maxHeight: '75px',
                            overflowY: 'auto',
                        }),
                    }}
                />
                <ReactSelect
                    isMulti
                    options={statusOptions}
                    placeholder="Filter by statuses"
                    onChange={selectedOptions => setSelectedStatuses(selectedOptions)}
                    value={selectedStatuses}
                    styles={{
                        control: (base) => ({
                            ...base,
                            width: '300px',
                            marginLeft: '2px',
                            marginRight: '4px',
                        }),
                        menuList: (provided) => ({
                            ...provided,
                            maxHeight: '200px',
                            overflowY: 'auto'
                        }),
                        multiValue: (base) => ({
                            ...base,
                        }),
                        valueContainer: (base) => ({
                            ...base,
                            maxHeight: '75px',
                            overflowY: 'auto',
                        }),
                    }}
                />
            </Flex>
            <Table variant="striped" size="md">
                <Thead bg="gray.600">
                    <Tr>
                        <Th></Th> {/* Edit column, no sorting */}
                        <Th color="white" fontWeight="bold" cursor="pointer" onClick={() => handleColumnClick('name')}>Name</Th>
                        <Th color="white" fontWeight="bold" cursor="pointer" onClick={() => handleColumnClick('email')}>Email</Th>
                        <Th color="white" fontWeight="bold" cursor="pointer" onClick={() => handleColumnClick('position')}>Position</Th>
                        <Th color="white" fontWeight="bold" cursor="pointer" onClick={() => handleColumnClick('status')}>Status</Th>
                        <Th color="white" fontWeight="bold" cursor="pointer" onClick={() => handleColumnClick('nextSteps')}>Next Steps</Th>
                        <Th color="white" fontWeight="bold" cursor="pointer" onClick={() => handleColumnClick('last_contacted')}>
                            Last Contacted
                            {sortConfig.key === 'last_contacted' && (sortConfig.direction === 'ascending' ? ' 🔼' : ' 🔽')}
                        </Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {paginatedCandidates.map((candidate, index) => (
                        <Tr key={index}>
                            <Td>
                                <IconButton
                                    icon={<EditIcon />}
                                    onClick={() => onEdit(candidate)}
                                    aria-label="Edit candidate"
                                    variant="ghost"
                                />
                            </Td>
                            <Td>{candidate.name}</Td>
                            <Td>{candidate.email}</Td>
                            <Td>{candidate.position}</Td>
                            <Td>{candidate.status}</Td>
                            <Td>{candidate.next_steps}</Td>
                            <Td>
                                {candidate.last_contacted ?
                                    new Date(candidate.last_contacted).toLocaleDateString() :
                                    'N/A'}
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
            <Box mt={4}>
                <Flex justifyContent="center" alignItems="center">
                    <IconButton
                        icon={<ChevronLeftIcon />}
                        isDisabled={currentPage === 1}
                        onClick={() => setCurrentPage(currentPage - 1)}
                        variant="ghost"
                    />
                    <Text mx={4}>
                        {`Page ${currentPage} of ${totalPages}`}
                    </Text>
                    <IconButton
                        icon={<ChevronRightIcon />}
                        isDisabled={currentPage === totalPages}
                        onClick={() => setCurrentPage(currentPage + 1)}
                        variant="ghost"
                    />
                </Flex>
            </Box>
        </Box>
    );
};

export default CandidateList;
