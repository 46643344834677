import React, { useState } from 'react';
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter,
    useToast, Button, Input, VStack, FormControl, FormLabel, Textarea, Select, SimpleGrid,
    Divider, Spacer, Heading
} from '@chakra-ui/react';
import { Formik, Form, Field, ErrorMessage, useFormik } from 'formik';
import ReactSelect from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as Yup from 'yup';
import axios from 'axios';

const CreateCandidate = ({ isAddModalOpen, setAddModalOpen, onCandidateCreated }) => {
    const toast = useToast();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [availableDate, setAvailableDate] = useState(new Date());
    const [rawValue, setRawValue] = useState('');
    const [displayValue, setDisplayValue] = useState('');
    const user = localStorage.getItem('userEmail');
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const [specialtyValues, setSpecialtyValues] = useState([]);
    const [certificationValues, setCertificationValues] = useState([]);

    const initialValues = {
        status: '',
        name: '',
        email: '',
        phone: '',
        position: '',
        notes: '',
        nextSteps: '',
        expectedSalary: '',
        seniority: '',
        specialties: [],
        certifications: [],
        availability: availableDate,
        lastContacted: selectedDate,
        linkedIn: '',
        resume: null,
        education: ''
    };

    const validationSchema = Yup.object().shape({
        status: Yup.string().required('Status is required'),
        name: Yup.string().required('Name is required'),
        email: Yup.string().email('Invalid email').required('Email is required'),
        phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
        position: Yup.string().required('Position is required'),
        notes: Yup.string(),
        nextSteps: Yup.string(),
        expectedSalary: Yup.number().positive('Expected salary must be positive'),
        seniority: Yup.string().required('Seniority is required'),
        specialties: Yup.array(),
        certifications: Yup.array(),
        availability: Yup.string(),
        linkedIn: Yup.string().url('Invalid URL'),
        education: Yup.string().required('Education level is required')
        //resume: Yup.mixed()
    });

    const onSubmit = async (values, { setSubmitting, resetForm }) => {
        values.specialties = specialtyValues;
        try {
            const response = await axios.post(process.env.REACT_APP_CANDIDATE_API_URL, {
                action: 'createNew',
                data: values,
                user: user
            });

            if (response.data.statusCode === 200) {
                toast({
                    title: "Candidate Added",
                    position: "top",
                    description: "Candidate has been added successfully",
                    status: "success",
                    duration: 5000,
                    isClosable: true
                });
                onCandidateCreated();
                setAddModalOpen(false);
                resetForm();
            } else {
                toast({
                    title: "Error",
                    position: "top",
                    description: "An error occurred while adding the candidate",
                    status: "error",
                    duration: 5000,
                    isClosable: true
                });
            }
        } catch (err) {
            console.log(err);
            toast({
                title: "Error",
                position: "top",
                description: "An error occurred while adding the candidate",
                status: "error",
                duration: 5000,
                isClosable: true
            });
        } finally {
            setSubmitting(false);
        }
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnMount: false,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit,

    });

    const resetForm = () => {
        formik.resetForm();
        setSelectedDate(new Date());
        setAvailableDate(new Date());
        setRawValue('');
        setDisplayValue('');
    };

    const handleCloseModal = () => {
        resetForm();
        setAddModalOpen(false);
    };

    const PhoneInput = ({ field, form, ...props }) => {
        const formatPhoneNumber = (val) => {
            if (!val) return val;
            const phoneNumber = val.replace(/[^\d]/g, '');
            if (phoneNumber.length < 4) return phoneNumber;
            if (phoneNumber.length < 7) {
                return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
            }
            return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
        };

        const handleChange = (e) => {
            const formattedPhoneNumber = formatPhoneNumber(e.target.value);
            form.setFieldValue(field.name, formattedPhoneNumber);
            form.setFieldTouched(field.name, true);
        };

        return (
            <Input
                {...field}
                {...props}
                value={formatPhoneNumber(field.value)}
                onChange={handleChange}
                onBlur={() => form.setFieldTouched(field.name)}
            />
        );
    };

    const handleValueChange = (e) => {
        const value = e.target.value.replace(/[^0-9.]/g, '');
        setRawValue(value);
        formik.setFieldValue('expectedSalary', value ? parseFloat(value) : '');
    };

    const handleValueBlur = (e) => {
        formik.handleBlur(e);
        if (formik.values.expectedSalary) {
            setDisplayValue(new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(formik.values.expectedSalary));
        } else {
            setDisplayValue('');
        }
        setRawValue('');
    };

    const handleValueFocus = () => {
        setRawValue(formik.values.expectedSalary.toString());
        setDisplayValue('');
    };

    return (
        <>
            <Modal isOpen={isAddModalOpen} onClose={handleCloseModal} size="6xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader align="center">
                        <Heading size="lg">Create New Candidate</Heading>
                    </ModalHeader>
                    <ModalCloseButton />
                    <Divider />
                    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                        {({ isSubmitting, isValid, dirty, touched, errors, setFieldValue, values }) => (
                            <Form>
                                <ModalBody>
                                    <SimpleGrid columns={2} spacing={4}>
                                        <FormControl isRequired>
                                            <FormLabel htmlFor="status">Status</FormLabel>
                                            <Field
                                                as={Select}
                                                name="status"
                                                placeholder="Select status"
                                                borderColor={touched.status && errors.status ? 'red.500' : 'gray.200'}
                                                borderWidth={touched.status && errors.status ? '2px' : '1px'}
                                            >
                                                <option value="Application Received">Application Received</option>
                                                <option value="Under Review">Under Review</option>
                                                <option value="Shortlisted">Shortlisted</option>
                                                <option value="Interview Scheduled">Interview Scheduled</option>
                                                <option value="Interview Completed">Interview Completed</option>
                                                <option value="Background Check">Background Check</option>
                                                <option value="Offer Extended">Offer Extended</option>
                                                <option value="Contingent Offer Extended">Contingent Offer Extended</option>
                                                <option value="Offer Accepted">Offer Accepted</option>
                                                <option value="Contingent Offer Accepted">Contingent Offer Accepted</option>
                                                <option value="Pending Clearance Crossover">Pending Clearance Crossover</option>
                                                <option value="Offer Declined">Offer Declined</option>
                                                <option value="On Hold">On Hold</option>
                                                <option value="Rejected">Rejected</option>
                                                <option value="Hired">Hired</option>
                                                <option value="Withdrawn">Withdrawn</option>
                                            </Field>
                                            <ErrorMessage name="status" />
                                        </FormControl>

                                        <FormControl isRequired>
                                            <FormLabel htmlFor="name">Full Name</FormLabel>
                                            <Field
                                                name="name"
                                                as={Input}
                                                placeholder="John Doe"
                                                borderColor={touched.name && errors.name ? 'red.500' : 'gray.200'}
                                                borderWidth={touched.name && errors.name ? '2px' : '1px'}
                                            />
                                            <ErrorMessage name="name" />
                                        </FormControl>

                                        <FormControl isRequired>
                                            <FormLabel htmlFor="email">Email Address</FormLabel>
                                            <Field
                                                name="email"
                                                type="email"
                                                as={Input}
                                                placeholder="john.doe@example.com"
                                                borderColor={touched.email && errors.email ? 'red.500' : 'gray.200'}
                                                borderWidth={touched.email && errors.email ? '2px' : '1px'}
                                            />
                                            <ErrorMessage name="email" />
                                        </FormControl>

                                        <FormControl>
                                            <FormLabel htmlFor="phone">Phone Number</FormLabel>
                                            <Field name="phone">
                                                {({ field, form, meta }) => (
                                                    <PhoneInput
                                                        field={field}
                                                        form={form}
                                                        borderColor={meta.touched && meta.error ? 'red.500' : 'gray.200'}
                                                        borderWidth={meta.touched && meta.error ? '2px' : '1px'}
                                                    />
                                                )}
                                            </Field>
                                            <ErrorMessage name="phone" />
                                        </FormControl>

                                        <FormControl isRequired>
                                            <FormLabel htmlFor="seniority">Seniority</FormLabel>
                                            <Field
                                                as={Select}
                                                name="seniority"
                                                placeholder="Select seniority level"
                                                borderColor={touched.seniority && errors.seniority ? 'red.500' : 'gray.200'}
                                                borderWidth={touched.seniority && errors.seniority ? '2px' : '1px'}
                                            >
                                                <option value="Junior (0-5 years)">Junior (0-5 years)</option>
                                                <option value="Mid-level (5-10 years)">Mid-level (5-10 years)</option>
                                                <option value="Senior (10+ years)">Senior (10+ years)</option>
                                            </Field>
                                            <ErrorMessage name="seniority" />
                                        </FormControl>

                                        <FormControl isRequired>
                                            <FormLabel htmlFor="education">Education Level</FormLabel>
                                            <Field
                                                as={Select}
                                                name="education"
                                                placeholder="Select education level"
                                                borderColor={touched.position && errors.position ? 'red.500' : 'gray.200'}
                                                borderWidth={touched.position && errors.position ? '2px' : '1px'}
                                            >
                                                <option value="High School Diploma">High School Diploma</option>
                                                <option value="Bacehlor's in IT field">Bacehlor's in IT field</option>
                                                <option value="Bachelor's in Other">Bachelor's in Other</option>
                                                <option value="Master's in IT field">Master's in IT field</option>
                                                <option value="Master's in Other">Master's in Other</option>
                                                <option value="Doctorate in IT field">Doctorate in IT field</option>
                                                <option value="Doctorate in Other">Doctorate in Other</option>
                                            </Field>
                                            <ErrorMessage name="education" />
                                        </FormControl>

                                        <FormControl isRequired>
                                            <FormLabel htmlFor="position">Position</FormLabel>
                                            <Field
                                                as={Select}
                                                name="position"
                                                placeholder="Select position"
                                                borderColor={touched.position && errors.position ? 'red.500' : 'gray.200'}
                                                borderWidth={touched.position && errors.position ? '2px' : '1px'}
                                            >
                                                <option value="Systems Engineer">Systems Engineer</option>
                                                <option value="Software Developer">Software Developer</option>
                                                <option value="Infrastructure Engineer">Infrastructure Engineer</option>
                                                <option value="Systems Administrator">Systems Administrator</option>
                                                <option value="Cyber Security Engineer">Cyber Security Engineer</option>
                                                <option value="Data Scientist">Data Scientist</option>
                                                <option value="Information Assurance Engineer">Information Assurance Engineer</option>
                                            </Field>
                                            <ErrorMessage name="position" />
                                        </FormControl>

                                        <FormControl id="expectedSalary">
                                            <FormLabel>Expected Salary</FormLabel>
                                            <Input
                                                name="expectedSalary"
                                                value={rawValue || displayValue}
                                                onChange={handleValueChange}
                                                onBlur={handleValueBlur}
                                                onFocus={handleValueFocus}
                                            />
                                            <ErrorMessage name="expectedSalary" />
                                        </FormControl>

                                        <FormControl>
                                            <FormLabel htmlFor="linkedIn">LinkedIn Profile</FormLabel>
                                            <Field
                                                name="linkedIn"
                                                as={Input}
                                                placeholder="https://linkedin.com/in/example"
                                                borderColor={touched.linkedIn && errors.linkedIn ? 'red.500' : 'gray.200'}
                                                borderWidth={touched.linkedIn && errors.linkedIn ? '2px' : '1px'}
                                            />
                                            <ErrorMessage name="linkedIn" />
                                        </FormControl>

                                        <FormControl>
                                            <FormLabel htmlFor="specialties">Specialties</FormLabel>
                                            <ReactSelect
                                                isMulti
                                                options={[
                                                    { value: 'Linux Administration', label: 'Linux Administration' },
                                                    { value: 'Windows Administration', label: 'Windows Administration' },
                                                    { value: 'Python Development', label: 'Python Development' },
                                                    { value: 'Java Development', label: 'Java Development' },
                                                    { value: 'AWS Cloud Infrastructure', label: 'AWS Cloud Infrastructure' },
                                                    { value: 'Azure Cloud Infrastructure', label: 'Azure Cloud Infrastructure' },
                                                    { value: 'GCP Cloud Infrastructure', label: 'GCP Cloud Infrastructure' },
                                                    { value: 'Cyber Security', label: 'Cyber Security' },
                                                    { value: 'Data Science', label: 'Data Science' },
                                                    { value: 'Machine Learning', label: 'Machine Learning' },
                                                    { value: 'Artificial Intelligence', label: 'Artificial Intelligence' },
                                                    { value: 'DevOps', label: 'DevOps' },
                                                    { value: 'Full Stack Development', label: 'Full Stack Development' },
                                                    { value: 'Front End Development', label: 'Front End Development' },
                                                    { value: 'Back End Development', label: 'Back End Development' },
                                                    { value: 'Database Administration', label: 'Database Administration' },
                                                    { value: 'Networking', label: 'Networking' }
                                                ]}
                                                placeholder="Select specialties"
                                                name="specialties"
                                                onChange={(selectedOptions) => {
                                                    const values = selectedOptions ? selectedOptions.map(option => option.value) : [];
                                                    setSpecialtyValues(values);
                                                }}
                                                onBlur={formik.handleBlur}
                                                value={specialtyValues.map(value => ({ label: value, value: value }))}
                                            />
                                            <ErrorMessage name="specialties" />
                                        </FormControl>

                                        <FormControl>
                                            <FormLabel htmlFor="certifications">Certifications</FormLabel>
                                            <ReactSelect
                                                isMulti
                                                options={[
                                                    { value: 'CISSP', label: 'Certified Information Systems Security Professional (CISSP)' },
                                                    { value: 'CEH', label: 'Certified Ethical Hacker (CEH)' },
                                                    { value: 'CISM', label: 'Certified Information Security Manager (CISM)' },
                                                    { value: 'CompTIA Security+', label: 'CompTIA Security+' },
                                                    { value: 'CCNA', label: 'Cisco Certified Network Associate (CCNA)' },
                                                    { value: 'CCNP', label: 'Cisco Certified Network Professional (CCNP)' },
                                                    { value: 'GCIH', label: 'GIAC Certified Incident Handler (GCIH)' },
                                                    { value: 'GPEN', label: 'GIAC Penetration Tester (GPEN)' },
                                                    { value: 'GWAPT', label: 'GIAC Web Application Penetration Tester (GWAPT)' },
                                                    { value: 'AWS Certified Solutions Architect', label: 'AWS Certified Solutions Architect' },
                                                    { value: 'AWS Certified Cloud Practitioner', label: 'AWS Certified Cloud Practitioner (CCP)' },
                                                    { value: 'Azure Certified Solutions Architect', label: 'Microsoft Certified: Azure Solutions Architect Expert' },
                                                    { value: 'Microsoft Certified: Azure Fundamentals', label: 'Microsoft Certified: Azure Fundamentals (AZ-900)' },
                                                    { value: 'GCP Professional Cloud Architect', label: 'Google Certified Professional Cloud Architect' },
                                                    { value: 'Google Associate Cloud Engineer', label: 'Associate Cloud Engineer (Google Cloud)' },
                                                    { value: 'Linux Professional Institute Certification', label: 'Linux Professional Institute Certification (LPIC)' },
                                                    { value: 'Red Hat Certified Engineer', label: 'Red Hat Certified Engineer (RHCE)' },
                                                    { value: 'Certified ScrumMaster', label: 'Certified ScrumMaster (CSM)' },
                                                    { value: 'Project Management Professional', label: 'Project Management Professional (PMP)' },
                                                    { value: 'ITIL', label: 'Information Technology Infrastructure Library (ITIL)' },
                                                    { value: 'ISACA', label: 'Information Systems Audit and Control Association (ISACA)' },
                                                    { value: 'CompTIA Advanced Security Practitioner', label: 'CompTIA Advanced Security Practitioner (CASP+)' },
                                                    { value: 'Offensive Security Certified Professional', label: 'Offensive Security Certified Professional (OSCP)' },
                                                    { value: 'Certified Data Professional', label: 'Certified Data Professional (CDP)' },
                                                    { value: 'Certified Information Systems Auditor', label: 'Certified Information Systems Auditor (CISA)' },
                                                    { value: 'Certified Cloud Security Professional', label: 'Certified Cloud Security Professional (CCSP)' }
                                                ]}                                                                                               
                                                placeholder="Select certifications"
                                                name="certifications"
                                                onChange={(selectedOptions) => {
                                                    const values = selectedOptions ? selectedOptions.map(option => option.value) : [];
                                                    setCertificationValues(values);
                                                }}
                                                onBlur={formik.handleBlur}
                                                value={certificationValues.map(value => ({ label: value, value: value }))}
                                            />
                                            <ErrorMessage name="certifications" />
                                        </FormControl>

                                        <FormControl>
                                            
                                        </FormControl>

                                        <FormControl>
                                            <FormLabel htmlFor="notes">Notes</FormLabel>
                                            <Field
                                                name="notes"
                                                as={Textarea}
                                            />
                                            <ErrorMessage name="notes" />
                                        </FormControl>

                                        <FormControl>
                                            <FormLabel htmlFor="nextSteps">Next Steps</FormLabel>
                                            <Field name="nextSteps" as={Textarea} />
                                            <ErrorMessage name="nextSteps" />
                                        </FormControl>

                                        <FormControl>
                                            <FormLabel htmlFor="availability">Availability</FormLabel>
                                            <DatePicker
                                                selected={availableDate}
                                                onChange={(date) => {
                                                    setAvailableDate(date);
                                                    setFieldValue('availability', date);
                                                }}
                                                borderColor={touched.availability && errors.availability ? 'red.500' : 'gray.200'}
                                                borderWidth={touched.availability && errors.availability ? '2px' : '1px'}
                                            />
                                            <ErrorMessage name="availability" />
                                        </FormControl>

                                        <FormControl>
                                            <FormLabel htmlFor="lastContacted">Last Contacted</FormLabel>
                                            <DatePicker
                                                selected={selectedDate}
                                                onChange={(date) => {
                                                    setSelectedDate(date);
                                                    setFieldValue('lastContacted', date);
                                                }}
                                                borderColor={touched.lastContacted && errors.lastContacted ? 'red.500' : 'gray.200'}
                                                borderWidth={touched.lastContacted && errors.lastContacted ? '2px' : '1px'}
                                            />
                                            <ErrorMessage name="lastContacted" />
                                        </FormControl>

                                        <FormControl>
                                            <FormLabel htmlFor="resume">Resume</FormLabel>
                                            <input id="resume" name="resume" type="file" accept=".pdf,.doc,.docx" onChange={(event) => {
                                                setFieldValue("resume", event.currentTarget.files[0]);
                                            }} />
                                            <ErrorMessage name="resume" />
                                        </FormControl>

                                    </SimpleGrid>
                                </ModalBody>
                                <Divider />
                                <ModalFooter>
                                    <Button ml={3} onClose={handleCloseModal}>Cancel</Button>
                                    <Spacer />
                                    <Button
                                        type="submit"
                                        isLoading={isSubmitting}
                                        colorScheme="blue"
                                        isDisabled={!isValid || !dirty}
                                    >
                                        Add Candidate
                                    </Button>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </ModalContent>
            </Modal>
        </>
    );
};

export default CreateCandidate;