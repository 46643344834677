import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Heading,
  Text,
  Grid,
  Flex,
  Input,
  useColorModeValue,
  Stack,
  Spinner
} from '@chakra-ui/react';
import { isLoggedIn, updateUserAttributes } from '../cognitoAuth';

const Profile = () => {
  const [user, setUser] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const bgColor = useColorModeValue("white", "gray.800");
  const color = useColorModeValue("gray.800", "white");

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    try {
      await updateUserAttributes(firstName, lastName);
      const loggedInUser = await isLoggedIn();
      setUser(loggedInUser);
      setIsEditing(false);
    } catch (error) {
      console.error("Failed to update user attributes: ", error);
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      setIsLoading(true);
      const loggedInUser = await isLoggedIn();
      setUser(loggedInUser);
      setFirstName(loggedInUser.firstName);
      setLastName(loggedInUser.lastName);
      setIsLoading(false);
    };
    fetchUser();
  }, []);

  return (
    <Flex direction="column" align="center" justify="center" m={8}>
      <Heading as="h1" size="2xl" mb={10} color={color}>{isEditing ? "Editing Profile" : "User Profile"}</Heading>
      {(!user || isLoading) ? (
        <Flex alignItems="center" justifyContent="center">
          <Spinner thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='black'
            size='xl'
          />
        </Flex>
      ) : (
        <>
          <Box w="500px" p={8} boxShadow="2xl" borderRadius="md" bg={bgColor}>
          <Grid templateColumns="repeat(2, 1fr)" gap={4}>
            <Text fontSize="lg" fontWeight="bold" color={color}>Username:</Text>
            <Text>{user.email}</Text>
            <Text fontSize="lg" fontWeight="bold" color={color}>First Name:</Text>
            {isEditing ? 
              <Input size="lg" value={firstName} onChange={e => setFirstName(e.target.value)} /> : 
              <Text>{user.firstName}</Text>
            }
            <Text fontSize="lg" fontWeight="bold" color={color}>Last Name:</Text>
            {isEditing ? 
              <Input size="lg" value={lastName} onChange={e => setLastName(e.target.value)} /> : 
              <Text>{user.lastName}</Text>
            }
          </Grid>
            <Stack direction="row" justify="center" spacing={4} mt={8}>
              {isEditing ?
                <Button colorScheme="blue" size="lg" onClick={handleSave} _hover={{ transform: "scale(1.02)" }} _active={{ transform: "scale(0.98)" }}>Save</Button> :
                <Button colorScheme="gray" size="lg" onClick={handleEdit} _hover={{ transform: "scale(1.02)" }} _active={{ transform: "scale(0.98)" }}>Edit</Button>
              }
            </Stack>
          </Box>
        </>
      )}
    </Flex>
  );
};

export default Profile;
