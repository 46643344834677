import React, { useState, useEffect } from 'react';
import LoginPage from './pages/login/LoginPage';
import { login, isLoggedIn, forgotPassword, confirmForgotPassword, signOut, signUp, listGroups } from './cognitoAuth';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import './App.css';
import LeftNavigation from './components/leftNavigation';
import UserRegistration from './pages/register/UserRegistration';
import ObfuscationPage from './pages/login/ObfuscationPage';
import Profile from './pages/Profile';
import Recruit from './pages/recruit/Recruit';
import Home from './pages/Home';
import FileEditor from './components/editor/Editor';
import { useNavigate, Navigate } from 'react-router-dom';
import { ChakraProvider, Box, Flex } from '@chakra-ui/react';

const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [userGroup, setUserGroup] = useState(null);
  const [firstName, setFirstName] = useState([]);
  const [lastName, setLastName] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isObfuscationPassed, setIsObfuscationPassed] = useState(
    localStorage.getItem("isObfuscationPassed") === "true"
  );
  
  useEffect(() => {
    const checkLoggedIn = async () => {
      const user = await isLoggedIn();
      
      if (user && isObfuscationPassed) {
        setUserEmail(user.attributes.email);
        localStorage.setItem('userEmail', user.attributes.email);
        setUserGroup(user.group);
        setFirstName(user.attributes.given_name);
        localStorage.setItem('firstName', user.attributes.given_name);
        setLastName(user.attributes.family_name);
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
      }
      setLoading(false);
    };
    
    checkLoggedIn();
  }, []);

  const handleLogin = async (email, password) => {
    try {
      const { result, userGroups } = await login(email, password);
      setLoggedIn(true);
      setUserEmail(email);
      localStorage.setItem('userEmail', email);
      setUserGroup(userGroups[0]);
      setFirstName(result.idToken.payload['given_name']);
      setLastName(result.idToken.payload['family_name']);
      localStorage.setItem('userGroup', userGroups[0]);
      setErrorMessage(null);
    } catch (error) {
      console.error('Login error:', error);
      if (error.message === 'FORCE_CHANGE_PASSWORD') {
        setErrorMessage('Please change your temporary password.');
      } else if (error.code === 'UserNotConfirmedException') {
        setErrorMessage('User is not confirmed. Please check your email for the confirmation link.');
      } else if (error.code === 'NotAuthorizedException') {
        setErrorMessage('Incorrect username or password.');
      } else {
        setErrorMessage('An error occurred. Please try again later.');
      }
    }
  };

  const handleForgotPassword = async (email) => {
    try {
      await forgotPassword(email);
      alert('Verification code sent. Please check your email.');
    } catch (error) {
      console.error('Forgot password error:', error);
      alert('Error sending verification code.');
    }
  };

  const handleConfirmForgotPassword = async (email, verificationCode, newPassword) => {
    try {
      await confirmForgotPassword(email, verificationCode, newPassword);
      alert('Password successfully reset.');
      return true;
    } catch (error) {
      console.error('Confirm forgot password error:', error);
      alert('Error resetting password.');
      return false;
    }
  };

  const handleSignOut = async () => {
    if (window.confirm("Are you sure you want to sign out?")) {
      await signOut();
      setLoggedIn(false);
      localStorage.setItem("isObfuscationPassed", false);
      window.location.assign('/');
    }
  };

  const handleRegister = async (email, password) => {
    try {
      await signUp(email, password);
      // Redirect the user to the confirmation page or display a success message
    } catch (error) {
      console.error('Registration error:', error);
      // Handle the error, e.g., display an error message
    }
  };

  return (
    <Router>
      <ChakraProvider>
        <div className="app-container">
          {!isObfuscationPassed ? (
            <Routes>
              <Route
                path="/"
                element={
                  <ObfuscationPage
                    onObfuscationPassed={() => setIsObfuscationPassed(true)}
                  />
                }
              />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          ) : (
            <Flex direction="row" h="100vh">
              {loggedIn && <LeftNavigation userGroup={localStorage.getItem('userGroups')} handleSignOut={handleSignOut} userEmail={userEmail} firstName={firstName} lastName={lastName} />}
              <Box as="main" flexGrow={1} overflowY="auto">
                <Routes>
                  {loggedIn ? (
                    <>
                      {/* <Route path="/home" element={<Home />} /> */}
                      <Route path="/home" element={<Navigate to="/recruit" />} />
                      <Route path="/recruit" element={<Recruit />} />
                      <Route path="/profile" element={<Profile />} />
                      <Route path="/" element={<Navigate to="/home" />} />
                    </>
                  ) : (
                    <>
                      <Route
                        path="/login"
                        element={
                          <LoginPage
                            onLogin={handleLogin}
                            onForgotPassword={handleForgotPassword}
                            onConfirmForgotPassword={handleConfirmForgotPassword}
                            errorMessage={errorMessage}
                          />
                        }
                      />
                      <Route
                        path="/register"
                        element={<UserRegistration onSignUp={handleRegister} errorMessage={errorMessage} />}
                      />
                      <Route path="/" element={<Navigate to="/login" />} />
                    </>
                  )}
                  <Route path="*" element={<Navigate to={loggedIn ? "/home" : "/login"} replace />} />
                </Routes>
              </Box>
            </Flex>
          )}
        </div>
      </ChakraProvider>
    </Router>
  );
  


};
export default App;