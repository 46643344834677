import React, { useState, useEffect } from 'react';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faUsers
} from '@fortawesome/free-solid-svg-icons';
import {
  Box,
  Text,
  Flex,
  HStack,
  VStack,
  MenuDivider,
  Divider,
  Image,
  Menu as ChakraMenu,
  MenuButton,
  MenuItem as ChakraMenuItem,
  MenuList,
  Avatar,
  Portal
} from '@chakra-ui/react';
import vertekalLogoBlack from '../assets/vertekal_logo_black.png';
import vertekalLogoBlackSmall from '../assets/vertekal_logo_black_small.png';
import "../App.css";
import { fetchUserOrganization } from '../cognitoAuth.js';

const LeftNavigation = ({ userGroup, userEmail, firstName, lastName, handleSignOut }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [activeSection, setActiveSection] = useState('/home');
  const navigate = useNavigate();
  const [showAdminLink, setShowAdminLink] = useState(
    userGroup?.includes('OrgAdmins') || userGroup?.includes('SystemAdmins')
  );
  const [userOrg, setUserOrg] = useState(localStorage.getItem('userOrganization'));
  const location = useLocation();

  useEffect(() => {
    setActiveSection(location.pathname); // Set active section based on current pathname
  }, [location]);

  useEffect(() => {
    setShowAdminLink(userGroup?.includes('OrgAdmins') || userGroup?.includes('SystemAdmins'));
  }, [userGroup]);

  useEffect(() => {
    const getOrg = async () => {
      const org = await fetchUserOrganization();
      if (org) {
        setUserOrg(org);
      }
    }

    if (!userOrg || userOrg === "noOrgAssigned") {
      getOrg();
    }
  }, []);

  const handleToggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  const handleLogout = async () => {
    await handleSignOut();
    window.location.assign('/');
  };

  const handleNavigation = (path) => {
    navigate(path);
    setActiveSection(path);
  };

  return (
    <div style={{ display: "flex", height: "100vh", flexShrink: 0}}>
      <Sidebar
      className="customSidebarContainer"
        collapsed={collapsed}
        backgroundColor='#FFFFFF'
        style={{ display: "flex", flexDirection: "column"}}
        
      >
        <Menu iconShape="circle" style={{ color: "black" }}>
          {collapsed ? (
            <Box pt={4} pb={8}>
            <MenuItem className="menu-item"
              onClick={handleToggleSidebar}
              style={{ backgroundColor: '#FFFFFF' }}
            >
              <Image
                src={vertekalLogoBlackSmall}
                alt="Vertekal Logo" />
            </MenuItem>
            </Box>
          ) : (
            <Box pt={4} pb={8}>
              <MenuItem className="menu-item"
                onClick={handleToggleSidebar}
                style={{ backgroundColor: '#FFFFFF' }}
              >
                <Image src={vertekalLogoBlack} alt="Vertekal Logo" />
              </MenuItem>
            </Box>
          )}
          <MenuItem
            className={`menu-item ${activeSection === '/recruit' ? 'active' : ''}`}
            style={{
              boxShadow: activeSection === '/recruit' ? '0px 4px 2px rgba(0, 0, 0, 0.1)' : 'none',
            }}
            onClick={() => handleNavigation("/recruit")}
            icon={<FontAwesomeIcon icon={faUsers} />}
            title="Recruit"
          >
            {activeSection === '/recruit' && <div className="indicator"></div>}
            Recruiting
          </MenuItem>
        </Menu>
        
        <div style={{ display: "flex", position:"absolute", bottom: 0, flexDirection: "column", alignItems: "center", width: "100%" }}>
          {!collapsed ? (
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <Menu iconShape="circle" style={{ color: "black", width: "100%" }}>
                <MenuItem onClick={handleToggleSidebar} style={{ width: "100%" }}>
                  <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <FontAwesomeIcon icon={faAngleDoubleLeft} />
                  </div>
                </MenuItem>
              </Menu>
            </div>
          ) : (
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <Menu iconShape="circle" style={{ color: "black", width: "100%" }}>
                <MenuItem onClick={handleToggleSidebar} style={{ width: "100%" }}>
                  <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <FontAwesomeIcon icon={faAngleDoubleRight} />
                  </div>
                </MenuItem>
              </Menu>
            </div>
          )}
          <Divider mt={1} color="#FFFFFF" />

          <VStack style={{ paddingTop: "10px" }}>
            <div style={{ display: "flex", justifyContent: "center", overflow: "visible" }}>
              <ChakraMenu>
                <MenuButton
                  as={Box}
                  aria-label="User profile menu"
                  variant="ghost"
                  p={2}
                  borderRadius="xl"
                  _hover={{ transform: "scale(1.08)" }} _active={{ transform: "scale(0.98)" }}
                >
                  <Avatar
                    size="md"
                    name={`${firstName} ${lastName}`}
                    src="" // Replace this with the actual avatar URL if available
                  />
                </MenuButton>
                <Portal>
                <MenuList zIndex="popover">
                  <ChakraMenuItem>
                    <HStack>
                      <Avatar
                        size="sm"
                        name={`${firstName} ${lastName}`}
                        src="" // Replace this with the actual avatar URL if available
                      />
                      <Text>{userEmail}</Text>
                    </HStack>
                  </ChakraMenuItem>
                  <ChakraMenuItem as={Link} to="/profile">View Profile</ChakraMenuItem>
                  <MenuDivider borderColor="#E2E8F0" />
                  <ChakraMenuItem as={Link} to="/docs">Docs</ChakraMenuItem>
                  <ChakraMenuItem as={Link} to="/support">Get Support</ChakraMenuItem>
                  <ChakraMenuItem as={Link} to="/feedback">Feedback</ChakraMenuItem>
                  <MenuDivider borderColor="#E2E8F0" />
                  <ChakraMenuItem onClick={handleLogout}>Sign Out</ChakraMenuItem>
                </MenuList>
                </Portal>
              </ChakraMenu>
            </div>
            {!collapsed && (<Text color="black" fontSize="md" fontWeight="bold">{userOrg}</Text>)}
          </VStack>

          </div>
      </Sidebar>
    </div>
  );
};

export default LeftNavigation;
